import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";

export default class TagTemplate extends React.Component {
  render() {
    const { tag } = this.props.pageContext;
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>   
        <Helmet title={`Posts tagged as "${tag}" | ${config.siteTitle}`} />
        <div className="blog-container">

          <section className="hero is-medium is-primary">
            <div className="hero-body has-text-centered">
                <div className="container">
                    <h1 
                    data-sal="fade"
                    data-sal-delay="400"
                    data-sal-easing="linear"
                    className="title is-size-1 is-size-3-mobile" 
                    style={{marginBottom: ".75rem"}}
                    >
                        {tag}
                    </h1>
                </div>
            </div>
          </section>

          <section className="hero is-medium">
            <div className="hero-body">
              <div className="container">
                <div className="columns is-centered">
                  <PostListing postEdges={postEdges} />
                </div>
              </div>
            </div>
          </section>

        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
