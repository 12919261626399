import React from "react";
import { Link } from "gatsby";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (

      <div className="column is-three-fifths-desktop">

        {/* Your post list here. */
        postList.map(post => (

          <div key={post.title}>

            <Link style={{ marginBottom: '.5rem', display: 'block' }} to={post.path}>
              <span className="subtitle is-size-4">{post.title}</span>
            </Link>

            <div style={{ marginBottom: '.5rem' }} className="subtitle is-primary is-size-6 has-text-weight-medium">{post.date}</div>

            <div style={{ marginBottom: '1.25rem' }}>{post.excerpt}</div>

            <Link to={post.path}>
              <div className="button is-primary is-rounded">Read More</div>
            </Link>

            <hr style={{ margin: '3rem 0' }}></hr>

          </div>

        ))}

      </div>

    );
  }
}

export default PostListing;